<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col>
            <public-filter
              :action-methods="searchData"
              class="mb-2"
            />
          </b-col>
          <b-col
            cols="12"
            md="auto"
          >
            <b-button
              variant="success"
              :href="dowmloadExcel"
              target="_blank"
            >
              <FeatherIcon icon="DownloadIcon" /> Excel Aktar
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="auto"
          >
            <b-button
              variant="danger"
              to="/tirehotel/search"
            >
              <FeatherIcon icon="PlusIcon" /> Lastik Ekle
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <v-select
              v-model="id_com_tire_status"
              :options="tireStatuses"
              label="title"
              :reduce="item => item.id"
              placeholder="Durum"
              @input="searchData"
            />
          </b-col>
          <b-col cols="6">
            <v-select
              v-model="id_com_location"
              :options="locations"
              label="title"
              :reduce="item => item.id"
              placeholder="Lokasyon"
              @input="searchData"
            />
          </b-col>
        </b-row>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        :fields="fields"
        :items="dataList"
      >
        <template #cell(customer)="data">
          <div>{{ data.item.customer }}</div>
          <div class="font-small-2 text-warning">
            {{ data.item.company }}
          </div>
          <div class="text-muted font-small-2 font-italic">
            <template v-if="data.item.modified">
              {{ moment(data.item.modified).format('DD.MM.YYYY') }} tarihinde {{ data.item.username }} güncelledi.
            </template>
            <template v-else>
              {{ moment(data.item.created).format('DD.MM.YYYY') }} tarihinde {{ data.item.username }} oluşturdu.
            </template>
          </div>
        </template>
        <template #cell(brand)="data">
          <div class="font-small-2 text-muted">
            {{ data.item.brand }} {{ data.item.model }}
          </div>
          <div class="text-primary">
            {{ data.item.license_plate }}
          </div>
        </template>
        <template #cell(tire_size)="data">
          <div>{{ data.item.tire_size }}</div>
          <div class="font-small-2 text-warning">
            {{ data.item.tire_brand }} - {{ data.item.tire_type }}
          </div>
          <div class="font-small-2 text-danger">
            {{ data.item.tire_status }}
          </div>
        </template>
        <template #cell(control)="data">
          <b-button
            variant="primary"
            :to="'/tirehotel/edit/' + data.item.id"
            block
          >
            <FeatherIcon icon="EditIcon" />
            Güncelle
          </b-button>
          <!--          <b-button-->
          <!--            variant="danger"-->
          <!--            size="sm"-->
          <!--            :to="'/tirehotel/change/' + data.item.id"-->
          <!--            block-->
          <!--          >-->
          <!--            Değiştir-->
          <!--          </b-button>-->
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BCol, BTable, BButton, BPagination, BCard, BCardFooter, BCardBody, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PublicFilter from '@/views/Spareparts/TireHotel/Filter/PublicFilter.vue'

export default {
  name: 'Index',
  components: {
    BCol,
    BTable,
    BButton,
    BPagination,
    BCard,
    BCardFooter,
    BCardBody,
    BRow,
    PublicFilter,
    vSelect,
  },
  data() {
    return {
      dowmloadExcel: `${this.$store.state.app.baseURL}/exports/data/tirehotel_export/excel`,
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
        },
        {
          key: 'brand',
          label: 'Araç Bilgisi',
          thClass: 'width-200',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'tire_size',
          label: 'Lastik',
          thClass: 'width-100',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'tire_shelf',
          label: 'Raf',
          thClass: 'width-100',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      id_com_location: null,
      id_com_tire_status: null,
      dataQuery: {
        select: [
          'com_tire_hotel.id AS id',
          'com_customer.name AS customer',
          'com_customer.company_name AS company',
          'com_swap_brand.name AS brand',
          'com_swap_model.title AS model',
          'com_cars.license_plate AS license_plate',
          'com_tire_size.title AS tire_size',
          'com_tire_brand.title AS tire_brand',
          'com_tire_type.title AS tire_type',
          'com_tire_shelf.title AS tire_shelf',
          'com_tire_status.title AS tire_status',
          'com_user.name AS username',
          'com_tire_hotel.created AS created',
          'com_tire_hotel.modified AS modified',
        ],
        limit: 25,
        start: 0,
        order_by: ['com_tire_hotel.id', 'DESC'],
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['tireHotel/dataList']
    },
    dataCount() {
      return this.$store.getters['tireHotel/dataCounts']
    },
    quickSearch() {
      return this.$store.getters['tireHotel/getQuickSearch']
    },
    tireStatuses() {
      return this.$store.getters['tireStatuses/getTireStatuses']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
    this.getLocations()
    this.getTireStatuses()
  },
  methods: {
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        where: {
          'com_location.id !=': 4,
        },
      })
    },
    getTireStatuses() {
      this.$store.dispatch('tireStatuses/tireStatusesList')
    },
    getDataList() {
      this.$store.dispatch('tireHotel/getDataList', this.dataQuery)
    },
    searchData() {
      if (this.quickSearch.keyword) {
        this.dataQuery.or_like = {
          'com_customer.name': this.quickSearch.keyword,
          'com_customer.company_name': this.quickSearch.keyword,
          'com_customer.phone': this.quickSearch.keyword,
          'com_cars.license_plate': this.quickSearch.keyword,
        }
      } else {
        delete this.dataQuery.or_like
      }
      if (this.id_com_tire_status) {
        this.dataQuery.where = {
          'com_tire_hotel.id_com_tire_status': this.id_com_tire_status,
        }
      } else if (this.dataQuery.where) {
        delete this.dataQuery.where['com_tire_hotel.id_com_tire_status']
      }
      if (this.id_com_location) {
        this.dataQuery.where = {
          'com_tire_hotel.id_com_location': this.id_com_location,
        }
      } else if (this.dataQuery.where) {
        delete this.dataQuery.where['com_tire_hotel.id_com_location']
      }
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
  },
}
</script>
