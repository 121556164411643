<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-input-group>
          <b-form-input
            id="customer"
            v-model="quickSearch.keyword"
            placeholder="Müşteri Adı, Telefon, Araç Plakası..."
            @keydown.enter="searchAction"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="searchAction"
            >
              <FeatherIcon icon="SearchIcon" />
              Ara
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'PublicFilter',
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    actionMethods: {
      type: Function,
    },
  },
  computed: {
    quickSearch() {
      return this.$store.getters['tireHotel/getQuickSearch']
    },
  },
  methods: {
    filterAction() {
      this.actionMethods(this.filterData)
    },
    searchAction() {
      this.actionMethods()
    },
  },
}
</script>
